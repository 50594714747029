

import DeepCompsetAnalysisService, { DeepCompsetAnalysisServiceS } from '@/modules/deep-analysis/deep-analysis.service';
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { $enum } from 'ts-enum-util';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import COMPARE_TO from '@/modules/deep-analysis/constants/compare-to-filter.constant';

@Component({
    components: { CustomSelect },
})
export default class CompareToFilter extends Vue {
    @Inject(DeepCompsetAnalysisServiceS) deepCompsetAnalysisService!: DeepCompsetAnalysisService;

    get value() {
        return this.deepCompsetAnalysisService.compareTo;
    }

    set value(value: COMPARE_TO) {
        this.deepCompsetAnalysisService.compareTo = value;
    }

    get items(): Item[] {
        return [{
            value: COMPARE_TO.COMPSET_AVG,
            name: this.$tc('filters.deepcomp.avg'),
            disabled: false,

        }, {
            value: COMPARE_TO.LAST_YEAR,
            name: this.$tc('filters.deepcomp.last'),
            disabled: false,
        }];
    }
}
